<template>
    <div class="carousel-add-detail">
        <el-page-header @back="goBack"  :content="cposition">
            
        </el-page-header>
        <div class="add-content">
            <div class="carousel-form">
                <el-input v-model="word" style="width:200px"></el-input> <el-button type="primary" @click="init" >检索</el-button>
            </div>
            <div class="carousel-table">
                    <el-table
                    :data="list"
                    border
                    style="width: 100%">
                    <el-table-column
                        type="index">
                    </el-table-column>
                    <el-table-column
                        prop="img"
                        label="图片"
                        width="200">
                        <template slot-scope="scope">
                        <img :src="scope.row.img" alt="" style="width:150px">
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="title"
                        label="标题"
                        width="200">
                    </el-table-column>
                    <el-table-column
                        prop="intro"
                        label="介绍"
                        width="230">
                    </el-table-column>
                    <el-table-column
                        prop="authorName"
                        label="作者姓名"
                        width="230">
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="230">
                        <template slot-scope="scope">
                        <el-button @click="sureRelation(scope.row)" type="text" size="small">关联该文章</el-button>
                        </template>
                    </el-table-column>
                    </el-table>
            </div>
            <div style="position:relative;width:100%;height:30px;padding-top:20px">  
                <el-pagination style="position:absolute;right:0"
                    @size-change="handleSizeChange"
                    @prev-click="prevPage"
                    @next-click="nextPage" 
                    @current-change="currentPage"
                    :current-page="currentPg"
                    :page-sizes="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {QUERY,UPDATE}  from '@/services/dao.js';
export default {
    data(){
       return{
           list:[
               {
                id:1,img:"https://car2.autoimg.cn/cardfs/product/g27/M04/CE/0A/1024x0_1_q95_autohomecar__ChsEfF1cCLqAf3FFAArPJeOpUBI092.jpg",
                title:"啛啛喳喳",
                intro:"啛啛喳喳介绍",
                authorName:"小红"
               }
           ],
           position:-1,//0-上,1-下
           id:-1,
           currentPg:0,
           pageSize:20,
           total:0,
           word:""
       } 
    },
    created(){
        this.id=this.$route.query.id;
        this.position=this.$route.query.position;
        this.init();
    },
    computed:{
        cposition:function(){
            if(this.position===0)
                return "轮播副图(上)*关联文章";
            if(this.position===1){
                return "轮播副图(下)*关联文章";
            }
            return "";
        }
    },
    methods:{
        init(){
              this.searchInfo(this.pageSize,0);
        },       
        goBack() {
            this.$router.go(-1);
        },
       async sureRelation(row){
           //查询文章
            let info=  await   UPDATE("post","",' update_TtCarouselAssistant(where: {id: {_eq: '+this.id+'}}, _set: {article: '+row.id+'}) {     affected_rows   }');// info articleTitle
            if(info.data.update_TtCarouselAssistant.affected_rows>0){

            //alert("文章关联成功");
            this.$message({
                message: '文章关联成功',
                type: 'warning'
            });
            }
        },
        //前一页
        prevPage(val){
            this.pageNum=val-1;
            this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
        },
        //下一页
        nextPage(val){
            this.pageNum=val-1;
            this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
        },
        //当前页
        currentPage(val){
            console.log(val);
            this.pageNum=val-1;
            this.searchInfo(this.pageSize,this.pageSize*this.pageNum);
        },
        //切换每页条数
        handleSizeChange(val){
            this.pageSize=val;
           this.searchInfo(this.pageSize,0);
        },
        async  searchInfo(limit,offset){
            let info=  await  QUERY("post","",'  Article(limit: '+limit+', offset: '+offset+',  where: {keyword: {_like: "%'+this.word+'%"}}) {  id    createTime    content    commentNum    collectNum    browseNum    authorName    accessory    img    intro    keyword    praiseNum    reference    theme    title        isChoiceness    isRecommend    hotNum    authorId }  Article_aggregate (where: {keyword: {_like: "%'+this.word+'%"}}){    aggregate {      count    }  }');
            this.list.splice(0,this.list.length);
            for(let i=0;i<info.data.Article.length;i++){
                this.list.push({
                    title:info.data.Article[i].title,
                    intro:info.data.Article[i].intro,
                    authorName:info.data.Article[i].authorName,
                    img:info.data.Article[i].img,
                    id:info.data.Article[i].id,
                });
            }
            this.total=info.data.Article_aggregate.aggregate.count;
        }
                
    }
}
</script>

<style scoped>
    .add-content{
        margin-top:20px ;
    }
    .carousel-table{
        margin-top: 20px;
    }
</style>